import React from "react";
import icon from "../static/img/nav/search.svg";
import "../static/css/Search.css";

const Search = () => {
  return (
    <div className="">
      {/* <div className="min-w-fit">
        <div className="min-w-fit bg-[#dfe7ee] flex items-center px-2 rounded-md">
          <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#434343"><path d="M783.52-110.91 529.85-364.59q-29.76 23.05-68.64 36.57-38.88 13.52-83.12 13.52-111.16 0-188.33-77.17-77.17-77.18-77.17-188.33t77.17-188.33q77.17-77.17 188.33-77.17 111.15 0 188.32 77.17 77.18 77.18 77.18 188.33 0 44.48-13.52 83.12-13.53 38.64-36.57 68.16l253.91 254.15-63.89 63.66ZM378.09-405.5q72.84 0 123.67-50.83 50.83-50.82 50.83-123.67t-50.83-123.67q-50.83-50.83-123.67-50.83-72.85 0-123.68 50.83-50.82 50.82-50.82 123.67t50.82 123.67q50.83 50.83 123.68 50.83Z"/></svg>
          <input type="text" placeholder="Search" className="px-2 py-3 bg-[#dfe7ee] outline-none border-none"/>
        </div>
      </div> */}
    </div>
  );
};

export default Search;
// #dfe7ee